export const replaceBrackets = (val, data) => {
    Object.keys(data).forEach((key) => {
        if (val.includes(`[${key}]`)) {
            val = val.replace(`[${key}]`, data[key]);
        }
    });
    return val;
};

export default { replaceBrackets };
