<template>
    <div class="c w-xl content blobs-bg relative">

        <div class="s-up:w-10 m-up:w-8 l-up:w-6 xl-up:w-5 mb-xxl">
            <h2 class="w-10">{{ data.projects_title_nl }}</h2>
            <div class="color-secondary">
                <span v-html="data.projects_text_nl" class="mr-s" />
            </div>

            <div class="cat__filters buttons mt-l">
                <button
                    v-for="cat in categories"
                    :key="'cat_' + cat.id"
                    class="-s bg-gray-200"
                    :class="{ 'bg-accent': filteredCategory === cat.id }"
                    @click="filter(cat.id)"
                >
                    {{ cat.name }}
                </button>
            </div>
        </div>

        <div class="flex flex-wrap align-stretch projectslist relative z-5">
            <template v-for="(project, index) in projects">
                <Card
                    v-if="!filteredCategory || filteredCategory === project.category.id"
                    :key="project.id"
                    class="shadow-m"
                    :title="project.intro_title_nl"
                    :text="project.intro_text_nl"
                    :image="cdnurl(project.intro_image)"
                    imageRatio="ratio-2by1"
                    :action="{
                        title: 'Bekijk project',
                        class: 'bg-accent -s',
                        to: '/projecten/' + project.id + '/' + kebabcase(project.name),
                    }"
                    @click="
                        $router.push(
                            '/projecten/' + project.id + '/' + kebabcase(project.name)
                        ).catch((e) => {});
                    "
                >
                    <template v-slot:pretitle>
                        <div class="size-7 color-tertiary py-s leading-1 flex align-center">
                            <template v-if="project.location"><GoogleMapsIcon :size="14" class="mr-s" />{{ project.location }} -</template>
                            {{ project.name }}
                        </div>
                    </template>
                </Card>

                <ServiceCTAcard :key="'service' + project.id" v-if="index > 0 && index % 5 === 0" class="shadow-m" />
            </template>
        </div>
    </div>
</template>

<script>
import GoogleMapsIcon from 'vue-material-design-icons/GoogleMaps.vue';
import ServiceCTAcard from '@/components/ServiceCTACard.vue';
import kebabcase from 'lodash.kebabcase';

export default {
    name: 'Projects',
    components: { GoogleMapsIcon, ServiceCTAcard },
    data() {
        return {
            categories: [],
            projects: [],
            sortedIDs: [],
            filteredCategory: undefined,
        };
    },
    created() {
        this.getData();
    },
    methods: {
        kebabcase,

        getData() {
            this.$store.dispatch('get', 'items/sort/1').then((r) => {
                this.sortedIDs = r.sortedids;
                this.$store.dispatch('get', 'items/projects').then(this.processData);
            });
        },

        processData(r) {
            const ids = [];
            this.categories = r.reduce((acc, cur) => {
                if (cur.category && !ids.includes(cur.category.id)) {
                    acc.push(cur.category);
                    ids.push(cur.category.id);
                }
                return acc;
            }, []);

            const sortByArrayValue = ({ data, sortKey, array }) => {
                const indexes = array.reduce((acc, cur, index) => {
                    acc[cur] = index;
                    return acc;
                }, {});

                const getIndexPosition = (val) => {
                    if (!val) { return 0; }
                    return indexes[val] || 0;
                };

                return data.sort((a, b) => getIndexPosition(a[sortKey]) - getIndexPosition(b[sortKey]));
            };

            this.projects = sortByArrayValue({ data: r, sortKey: 'id', array: this.sortedIDs });
            this.setMeta(r);
        },

        setMeta(data) {
            if (this.data) {
                let schemas = [];
                if (this.$route.meta.schema) {
                    if (typeof this.$route.meta.schema === 'function') {
                        schemas = this.$route.meta.schema(Array.isArray(data) ? data : [], this);
                    } else {
                        schemas = this.$route.meta.schema;
                    }
                }

                this.$store.commit('setMeta', {
                    data: {
                        title: this.data.projects_title_nl,
                        titleDefault: `${process.env.VUE_APP_NAME}`,
                        description: this.data.projects_title_nl.substr(0, 100),
                    },
                    schemas,
                });
            }
        },

        filter(cat) {
            if (this.filteredCategory === cat) {
                this.filteredCategory = undefined;
            } else {
                this.filteredCategory = cat;
            }
        },
    },
    computed: {
        data() {
            return this.$store.getters.data;
        },
    },
    watch: {
        data: {
            handler: 'setMeta',
            immediate: true,
        },
    },
};
</script>

<style>
.projectslist .card {
    flex: 0 1 100%;
    margin-bottom: var(--size-l);
    margin-left: var(--size-l);
}
    @media (min-width: 34em) {
        .projectslist .card {
            flex: 0 1 calc(50% - var(--size-l));
        }
    }
    @media (min-width: 62em) {
        .projectslist .card {
            flex: 0 1 calc(33.33% - var(--size-l));
        }
    }
.projectslist {
    margin-left: calc(-1 * var(--size-l));
}
</style>
