<template>
    <div>
        <Header
            :title="project.intro_title_nl"
            :text="project.intro_text_nl"
            :image="cdnurl(project.intro_image1)"
            :image_title="project.intro_title_nl"
            type="large"
            :showUSPS="false"
        >
            <template v-slot:pretitle>
                <tag v-if="project.category" class="bg-accent -s rounded color-white px-r py-s size-7">{{ project.category.name }}</tag>
                <div class="size-6 color-secondary py-s leading-1 flex align-center">
                    <template v-if="project.location"><GoogleMapsIcon :size="14" class="mr-s" />{{ project.location }} -</template>
                    {{ project.name }}
                </div>
            </template>
        </Header>

        <Layout>
            <template v-slot:content>
                <div
                    class="color-secondary leading-m l-up:pr-xl accentlink richtext"
                    v-if="typeof project.config_nl === 'string'"
                    v-html="project.config_nl"
                />
            </template>
            <template v-slot:sidebar>
                <Sidebar type="project" :feedback="project.client_feedback" />
            </template>
        </Layout>
    </div>
</template>

<script>
import Header from '@/components/Header.vue';
import Layout from '@/components/Layout-Sidebar.vue';
import GoogleMapsIcon from 'vue-material-design-icons/GoogleMaps.vue';
import kebabcase from 'lodash.kebabcase';
import { replaceBrackets } from '@/assets/helpers/string';
import Sidebar from '@/components/Sidebar.vue';

export default {
    name: 'Project',
    components: {
        Header, GoogleMapsIcon, Sidebar, Layout,
    },
    data() {
        return {
            project: {},
        };
    },
    created() {
        if (window.PRERENDER_INJECTED) {
            const routedata = window.PRERENDER_INJECTED.routeData[this.$route.path];
            if (routedata) {
                this.processData(routedata);
            }
        } else {
            this.getData();
        }
    },
    methods: {
        kebabcase,

        getData() {
            const url = replaceBrackets(this.$route.meta.article.endpoint, this.$route.params);
            this.$store.dispatch('get', url).then(this.processData);
        },

        processData(r) {
            this.project = r;
            this.setMeta(r);
        },

        setMeta(data) {
            if (this.project) {
                let schemas = [];
                if (this.$route.meta.article.schema) {
                    if (typeof this.$route.meta.article.schema === 'function') {
                        schemas = this.$route.meta.article.schema(data, this);
                    } else {
                        schemas = this.$route.meta.article.schema;
                    }
                }

                this.$store.commit('setMeta', {
                    data: {
                        title: data.intro_title_nl,
                        titleDefault: `${process.env.VUE_APP_NAME}`,
                        description: data.intro_text_nl.substr(0, 100),
                    },
                    schemas,
                });
            }
        },
    },
    computed: {
        data() {
            return this.$store.getters.data;
        },
    },
    watch: {
        data: {
            handler: 'setMeta',
            immediate: true,
        },
    },
};
</script>

<style>
</style>
